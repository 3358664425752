<template>
  <v-row align="center">
    <v-col align="center">
      <v-btn v-on:click="Login" outlined color="indigo" x-large>Google Login</v-btn>
      <div style="font-size: 20px " class="red--text" v-if="this.not == true">You are not worthy</div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      not: ""
    };
  },
  methods: {
    Login: function() {
      this.$store
        .dispatch("signInUser")
        .then(() => {})
        .catch(() => {
          this.not = true;
        });
    }
  }
};
</script>
