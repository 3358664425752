import { db } from '../firebase';

export default {
  state: {
    urls: [],
  },
  actions: {
    getUrls({ commit }, projectId) {
      let newUrls = [];
      db.collection('rurls')
        .where('projectId', '==', projectId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let url = {
              mainUrl: doc.data().mainUrl,
              redirectUrl: doc.data().redirectUrl,
              domain: doc.data().domain,
              timeout: doc.data().timeout,
              projectId: doc.data().projectId,
              createdAt: doc.data().createdAt,
              id: doc.id,
            };
            newUrls.push(url);
          });
          newUrls.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
          commit('setUrls', newUrls);
        });
    },
    addUrl(context, url) {
      return new Promise((resolve, reject) => {
        let newUrl = {
          mainUrl: url.mainUrl,
          redirectUrl: url.domain + '/' + url.redirectUrl,
          domain: url.domain,
          timeout: url.timeout,
          projectId: url.projectId,
          createdAt: url.createdAt,
        };
        db.collection('rurls')
          .doc(url.redirectUrl)
          .set(newUrl)
          .then(() => {
            this.dispatch('getUrls', url.projectId);
            resolve(url);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editUrl(context, url) {
      let newUrl = {
        mainUrl: url.mainUrl,
        redirectUrl: url.redirectUrl,
        domain: url.domain,
        timeout: url.timeout,
        projectId: url.projectId,
        createdAt: url.createdAt,
      };
      db.collection('rurls')
        .doc(url.id)
        .update(newUrl)
        .then(() => {
          this.dispatch('getUrls', url.projectId);
        });
    },
    deleteUrl(context, item) {
      db.collection('rurls')
        .doc(item.id)
        .delete()
        .then(() => {
          this.dispatch('getUrls', item.projectId);
        });
    },
  },
  getters: {
    urls(state) {
      return state.urls;
    },
  },
  mutations: {
    setUrls(state, payload) {
      return (state.urls = payload);
    },
  },
};
