<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template  v-slot:activator="{ on }">
        <v-icon class="mr-4" v-on="on">mdi-settings</v-icon>
      </template>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  name="projectName"
                  v-model.trim="form.projectName"
                  :class="{
                    'error--text v-input--has-state':
                      $v.form.projectName.$dirty &&
                      !$v.form.projectName.required,
                  }"
                  label="Group Name"
                ></v-text-field>
              </v-col>
              <div class="red--text">
                <p
                  v-if="
                    $v.form.projectName.$dirty && !$v.form.projectName.required
                  "
                >Project Name is required</p>
              </div>
              <v-col cols="12">
                <v-textarea
                  name="googlePixel"
                  v-model.trim="form.googlePixel"
                  label="Google Pixel"
                  clearable
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea name="fbPixel" v-model.trim="form.fbPixel" label="Facebook Pixel" clearable></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="editProject">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  data: () => ({
    dialog: false,
    form: {
      projectName: "",
      createdAt: "",
      googlePixel: "",
      fbPixel: ""
    }
  }),
  props: {
    project: Object
  },
  validations: {
    form: {
      projectName: {
        required
      }
    }
  },
  methods: {
    editProject() {
      if (this.$v.$invalid) {
        this.$v.form.$touch();
        this.dialog = true;
        return;
      }
      let data = {
        id: this.$props.project.id,
        projectName: this.form.projectName,
        googlePixel: this.form.googlePixel,
        fbPixel: this.form.fbPixel,
        createdAt: this.$props.project.createdAt
      };
    

      this.$store
        .dispatch("editProject", data)
        .then(() => {
          this.$v.$reset();
          this.dialog = false;
        })
        .catch(error => {
          this.dialog = true;
          return error;
        });
    }
  },
  mounted() {
    this.form = { ...this.project };
  }
};
</script>
