import firebase from 'firebase/app';
import router from '../router';

export default {
  state: {
    user: null,
  },
  actions: {
    signInUser({ commit }) {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .signInWithPopup(new firebase.auth.GoogleAuthProvider())
          .then((user) => {
            if (
              user.user.email.slice(-9) === '@tcf.team'
            ) {
              const newUser = {
                displayName: user.user.displayName,
                email: user.user.email,
              };
              commit('setUser', newUser);
              router.push('/dashboard');
              resolve(newUser);
            } else {
              reject('error');
            }
          });
      });
    },
    autoSignIn({ commit }, payload) {
      if (
        payload.email.slice(-9) === '@tcf.team'
      ) {
        commit('setUser', {
          displayName: payload.displayName,
          email: payload.email,
        });
      }
    },
    logoutUser({ commit }) {
      firebase.auth().signOut();
      commit('setUser', null);
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    checkUser(state) {
      return !!state.user;
    },
  },
  mutations: {
    setUser(state, payload) {
      return (state.user = payload);
    },
  },
};
