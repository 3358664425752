import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuelidate from 'vuelidate'

// Firebase
import * as firebase from 'firebase';
//import VueFirestore from 'vue-firestore';

import vuetify from './plugins/vuetify';
import axios from 'axios';

window.axios = axios;
Vue.config.productionTip = false;

Vue.use(Vuelidate);

// Vue.use(VueFirestore, {
//   key: 'id',         // the name of the property. Default is '.key'.
//   enumerable: true  //  whether it is enumerable or not. Default is true.
// })


let myUrl = window.location.protocol + '//' + window.location.host;
export { myUrl };

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.dispatch('autoSignIn', user);
      }
    });
  },
}).$mount('#app');
