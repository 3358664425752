import Vue from 'vue';
import VueRouter from 'vue-router';
import AdminLogin from '../components/AdminLogin/AdminLogin';
import Dashboard from '../components/Dashboard/Dashboard';
import MyProject from '../components/MyProject/MyProject';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/adminlogin',
    name: 'adminlogin',
    component: AdminLogin,
    beforeEnter: (to, from, next) => {
      store.getters.user ? next('/dashboard') : next();
    },
  },
  {
    path: '/dashboard/:projectId',
    name: 'project',
    component: MyProject,
    props: true,
    beforeEnter: (to, from, next) => {
      store.getters.user ? next() : next('/adminlogin');
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      store.getters.user ? next() : next('/adminlogin');
    },
  },
];

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
