var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"green","dark":""}},on),[_vm._v("New Project")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{class:{
                  'error--text v-input--has-state':
                    _vm.$v.form.projectName.$dirty &&
                    !_vm.$v.form.projectName.required,
                },attrs:{"name":"projectName","label":"Project Name"},model:{value:(_vm.form.projectName),callback:function ($$v) {_vm.$set(_vm.form, "projectName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.projectName"}}),_c('div',{staticClass:"red--text"},[(
                    _vm.$v.form.projectName.$dirty &&
                      !_vm.$v.form.projectName.required
                  )?_c('p',[_vm._v(" Project Name is required ")]):_vm._e()])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"clearable":"","name":"googlePixel","label":"Google Pixel"},model:{value:(_vm.form.googlePixel),callback:function ($$v) {_vm.$set(_vm.form, "googlePixel", $$v)},expression:"form.googlePixel"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"name":"fbPixel","label":"Facebook Pixel","clearable":"","value":"5"},model:{value:(_vm.form.fbPixel),callback:function ($$v) {_vm.$set(_vm.form, "fbPixel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.fbPixel"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.addProject}},[_vm._v("Save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }