import { firebase } from '@firebase/app';
import '@firebase/firestore';

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDKy1LqHQkhoSDBw724TIB7VmW_deSQYE0",
  authDomain: "node-express-test-81f8b.firebaseapp.com",
  databaseURL: "https://node-express-test-81f8b.firebaseio.com",
  projectId: "node-express-test-81f8b",
  storageBucket: "node-express-test-81f8b.appspot.com",
  messagingSenderId: "712911026665",
  appId: "1:712911026665:web:58c26420d8b1395f"
});

export const db = firebaseApp.firestore();
