import { db } from '../firebase';

export default {
  state: {
    domains: [],
  },
  actions: {
    getDomains({ commit }) {
      let newDomains = [];
      db.collection('domains')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let domains = doc.data()
            for(let i in domains){
                newDomains.push(domains[i])
            }
          });
          commit('setDomains', newDomains);
        });
    },
  },
  getters: {
    domains(state) {
      return state.domains;
    },
  },
  mutations: {
    setDomains(state, payload) {
      return (state.domains = payload);
    },
  },
};
