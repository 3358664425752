<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <Header />
    </v-row>

    <v-row justify="center">
      <v-col cols="10" style="padding-top: 50px">
        <v-row justify="center">
          <v-col cols="10">
            <v-row align="center">
              <v-col cols="auto">
                <AddGroup />
              </v-col>
            </v-row>
            <div
              class="project d-flex row justify-space-between align-center"
              @click="toProject(project.id)"
              v-for="project in projects"
              :key="project.id"
            >
              <div class="d-flex ml-3" style="color: #595959">
                <span class="project-name">{{ project.projectName }}</span>
              </div>
              <div class="d-flex">
                <v-icon class="mr-4">mdi-pencil</v-icon>
                <EditGroup :project="project" />
                <v-icon @click.native.stop="deleteProject(project.id)">mdi-delete</v-icon>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AddGroup from "../AddGroup/AddGroup";
import EditGroup from "../EditGroup/EditGroup";
import Header from "../Header/Header";
import router from "../../router/index";

export default {
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...mapGetters({
      projects: "projects"
    })
  },
  methods: {
    toProject(id) {
      router.push({
        name: "project",
        params: { projectId: id }
      });
    },
    deleteProject(id) {
      let b = confirm("Delete this Project?");
      if (b) {
        this.$store.dispatch("deleteProject", id);
      }
    }
  },
  created() {
    this.$store.dispatch("getProjects");
  },
  components: {
    AddGroup,
    EditGroup,
    Header
  }
};
</script>
<style scoped>
.project {
  width: 100%;
  background-color: azure;
  height: 50px;
  cursor: pointer;
  border: 1px solid #595959;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 2px 5px;
}
  .project:hover{
    background-color: #00acc1;
  }
.project:hover .project-name{
  color: #ffffff;
}

</style>
