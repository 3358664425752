<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <Header />
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <v-data-table :headers="headers" :items="this.urls" sort-by="calories" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title></v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" dark class="mb-2" v-on="on">New Url</v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="form.redirectUrl"
                            :class="{'error--text v-input--has-state': ($v.form.redirectUrl.$dirty && !$v.form.redirectUrl.required) || ($v.form.redirectUrl.$error && !$v.form.redirectUrl.symb.valid)}"
                            label="Redirect url"
                          ></v-text-field>
                          <p
                            class="red--text"
                            v-if="$v.form.redirectUrl.$dirty && !$v.form.redirectUrl.required"
                          >Redirect Url is required</p>
                          <p
                            class="red--text"
                            v-if="$v.form.redirectUrl.$error && !$v.form.redirectUrl.symb.valid && $v.form.redirectUrl.required"
                          >Should not contain "?"'</p>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="form.mainUrl"
                            :class="{'error--text v-input--has-state':$v.form.mainUrl.$dirty && !$v.form.mainUrl.required}"
                            label="Main url"
                          ></v-text-field>
                          <p
                            class="red--text"
                            v-if="$v.form.mainUrl.$dirty && !$v.form.mainUrl.required"
                          >Main Url Name is required</p>
                        </v-col>

                        <v-col cols="12">
                          <v-select
                            :items="dom"
                            :key="dom.domain"
                            :menu-props="{ top: true, offsetY: true }"
                            :class="{'error--text v-input--has-state':$v.form.domain.$dirty && !$v.form.domain.required}"
                            label="Domain"
                            v-model="form.domain"
                          ></v-select>
                          <p
                            class="red--text"
                            v-if="$v.form.domain.$dirty && !$v.form.domain.required"
                          >Domain Name is required</p>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model.number="form.timeout"
                            :class="{'error--text v-input--has-state':($v.form.timeout.$dirty && !$v.form.timeout.required) || ($v.form.timeout.$dirty && !$v.form.timeout.numeric)}"
                            label="Redirect time"
                          ></v-text-field>
                          <p
                            class="red--text"
                            v-if="$v.form.timeout.$dirty && !$v.form.timeout.required"
                          >Timeout is required</p>
                          <p
                            class="red--text"
                            v-if="$v.form.timeout.$dirty && !$v.form.timeout.numeric"
                          >Timeout must be number</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="save(projectId)">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import Header from "../Header/Header";
import { symb } from "../../helpers/validate";
import { required, numeric } from "vuelidate/lib/validators";

export default {
  data: () => ({
    dialog: false,
    headers: [
      { text: "Redirect Url", value: "redirectUrl", sortable: false },
      { text: "Main Url", value: "mainUrl", sortable: false },
      { text: "Domain", value: "domain", sortable: false },
      { text: "Redirect time", value: "timeout", sortable: false },
      { text: "Actions", value: "actions", sortable: false }
    ],
    dom: [],
    radioGroup: 1,
    editedIndex: -1,
    formEmpty: {
      projectId: "",
      redirectUrl: "",
      mainUrl: "",
      domain: "https://tcf.one",
      timeout: "1",
      createdAt: ""
    },
    form: {
      projectId: "",
      redirectUrl: "",
      mainUrl: "",
      domain: "https://tcf.one",
      timeout: "1",
      createdAt: ""
    }
  }),
  validations: {
    form: {
      redirectUrl: {
        required,
        symb
      },
      mainUrl: {
        required
      },
      domain: {
        required
      },
      timeout: {
        required,
        numeric
      }
    }
  },
  props: {
    projectId: String
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Url" : "Edit Url";
    },
    ...mapGetters({
      urls: "urls"
    }),
    ...mapGetters({
      domains: "domains"
    }),
    checkUser() {
      return this.$store.getters.user;
    },
    ...mapGetters({
      user: "user"
    })
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.$store.dispatch("getUrls", this.projectId);
    this.$store.dispatch("getDomains");
    this.dom = [...this.domains]
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.urls.indexOf(item);
      this.form = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      let a = confirm("Delete this item?");
      if (a) {
        this.$store.dispatch("deleteUrl", item);
      }
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.form = Object.assign({}, this.formEmpty);
        this.editedIndex = -1;
      }, 300);
    },
    async save(projectId) {
      if (this.$v.$invalid) {
        this.$v.form.$touch();
        this.dialog = true;
        return;
      }
      if (this.editedIndex > -1) {
        Object.assign(this.urls[this.editedIndex], this.form);
        await this.$store.dispatch("editUrl", this.form).then(() => {
          this.$v.$reset();
        });
      } else {
        this.form.projectId = projectId;
        this.form.createdAt = new Date();
        await this.$store.dispatch("addUrl", this.form).then(() => {
          this.$v.$reset();
        });
      }
      this.close();
    }
  },
  components: {
    Header
  }
};
</script>