<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn color="green" dark v-on="on">New Project</v-btn>
      </template>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  name="projectName"
                  v-model.trim="form.projectName"
                  :class="{
                    'error--text v-input--has-state':
                      $v.form.projectName.$dirty &&
                      !$v.form.projectName.required,
                  }"
                  label="Project Name"
                ></v-text-field>
                <div class="red--text">
                  <p
                    v-if="
                      $v.form.projectName.$dirty &&
                        !$v.form.projectName.required
                    "
                  >
                    Project Name is required
                  </p>
                </div>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  clearable
                  name="googlePixel"
                  v-model="form.googlePixel"
                  label="Google Pixel"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  name="fbPixel"
                  v-model.trim="form.fbPixel"
                  label="Facebook Pixel"
                  clearable
                  value="5"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close</v-btn
          >
          <v-btn color="blue darken-1" text @click="addProject">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  data: () => ({
    dialog: false,
    form: {
      projectName: '',
      createdAt: '',
      googlePixel: '',
      fbPixel: '',
    },
  }),
  validations: {
    form: {
      projectName: {
        required,
      },
    },
  },
  methods: {
    addProject() {
      if (this.$v.$invalid) {
        this.$v.form.$touch();
        this.dialog = true;
        return;
      }
      let data = {
        projectName: this.form.projectName,
        googlePixel: this.form.googlePixel,
        fbPixel: this.form.fbPixel,
        createdAt: new Date(),
      };

      this.$store
        .dispatch('addProject', data)
        .then(() => {
          this.$v.$reset();

          this.dialog = false;
          this.form.projectName = '';
          this.form.googlePixel = '';
          this.form.fbPixel = '';
        })
        .catch((error) => {
          this.dialog = true;
          return error;
        });
    },
  },
};
</script>