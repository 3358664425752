<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col cols="12" style="padding: 0">
        <v-toolbar flat color="primary" height="70px">
          <router-link to="/dashboard">
            <v-toolbar-title
              style="color: aliceblue;padding-left: 20px;padding-bottom: 4px;font-size: 24px"
              >Dashboard</v-toolbar-title
            >
          </router-link>

          <v-spacer></v-spacer>
          <div v-if="checkUser">
            <span style="padding-right: 20px;font-size: 20px;color: azure">{{
              user.displayName
            }}</span>
          </div>
          <v-btn
            v-if="checkUser"
            type="submit"
            v-on:click="logout"
            text
            color="white"
            >Log Out</v-btn
          >
        </v-toolbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    checkUser() {
      return this.$store.getters.user;
    },
    ...mapGetters({
      user: 'user',
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch('logoutUser');
      this.$router.push('/adminlogin');
    },
  },
};
</script>
