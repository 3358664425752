import { db } from '../firebase';

export default {
  state: {
    projects: [],
  },
  actions: {
    addProject(context, data) {
      return new Promise((resolve, reject) => {
        db.collection('projects')
          .add(data)
          .then(() => {
            this.dispatch('getProjects');
            resolve('ok');
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editProject(context, data) {
      return new Promise((resolve, reject) => {
        db.collection('projects')
          .doc(data.id)
          .update(data)
          .then(() => {
            this.dispatch('getProjects');
            resolve('ok');
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getProjects({ commit }) {
      let newProjects = [];
      db.collection('projects')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let project = {
              projectName: doc.data().projectName,
              googlePixel: doc.data().googlePixel,
              fbPixel: doc.data().fbPixel,
              createdAt: doc.data().createdAt,
              id: doc.id,
            };
            newProjects.push(project);
            newProjects.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
          });
          commit('setProjects', newProjects);
        });
    },
    deleteProject(context, id) {
      db.collection('projects')
        .doc(id)
        .delete()
        .then(() => {
          db.collection('rurls')
            .where('projectId', '==', id)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                db.collection('rurls')
                  .doc(doc.id)
                  .delete();
              });
            });
          this.dispatch('getProjects');
        });
    },
  },
  getters: {
    projects(state) {
      return state.projects;
    },
  },
  mutations: {
    setProjects(state, payload) {
      return (state.projects = payload);
    },
  },
};
